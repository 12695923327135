import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/home/wechatIcon.png'
import _imports_1 from '../assets/home/arrowCard.png'
import _imports_2 from '../assets/home/questionMark.png'
import _imports_3 from '../assets/home/wechatbg.png'
import _imports_4 from '../assets/home/alipayIcon.png'
import _imports_5 from '../assets/home/alipaybg.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "welcome" }
const _hoisted_3 = { class: "left-msg" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "welcome-text" }
const _hoisted_6 = { class: "fc-6B6B86" }
const _hoisted_7 = { class: "fc-6B6B86" }
const _hoisted_8 = { class: "card bg1 mg-b-20" }
const _hoisted_9 = { class: "card-l" }
const _hoisted_10 = { class: "innerCard innerCardbg1" }
const _hoisted_11 = { class: "innermsg" }
const _hoisted_12 = { class: "bold fs-30" }
const _hoisted_13 = { class: "msgbox" }
const _hoisted_14 = { class: "msginfo" }
const _hoisted_15 = { class: "msgitem" }
const _hoisted_16 = { class: "fs-22 bold" }
const _hoisted_17 = { class: "msgitem" }
const _hoisted_18 = { class: "fs-22 bold" }
const _hoisted_19 = { class: "msgitem" }
const _hoisted_20 = { class: "fs-22 bold" }
const _hoisted_21 = { class: "card-r" }
const _hoisted_22 = { class: "card bg2" }
const _hoisted_23 = { class: "card-l" }
const _hoisted_24 = { class: "innerCard innerCardbg2" }
const _hoisted_25 = { class: "innermsg" }
const _hoisted_26 = { class: "bold fs-30" }
const _hoisted_27 = { class: "msgbox" }
const _hoisted_28 = { class: "msginfo" }
const _hoisted_29 = { class: "msgitem" }
const _hoisted_30 = { class: "fs-22 bold" }
const _hoisted_31 = { class: "msgitem" }
const _hoisted_32 = { class: "fs-22 bold" }
const _hoisted_33 = { class: "msgitem" }
const _hoisted_34 = { class: "fs-22 bold" }
const _hoisted_35 = { class: "card-r" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alipayRechargeDialog = _resolveComponent("alipayRechargeDialog")!
  const _component_wechatRechargeDialog = _resolveComponent("wechatRechargeDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "welcome-avatar",
          src: _ctx.$store.state.avatar
        }, null, 8, _hoisted_4),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_5, " 你好，" + _toDisplayString(_ctx.$store.state.companyName), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.accountProductStatus.account.accountExpireTime ?? '------') + "到期", 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.nowDate) + "更新", 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "fs-18 bold" }, "微信账户余额", -1)),
            _createElementVNode("i", _hoisted_12, "¥" + _toDisplayString(_ctx.accountProductStatus.pay.balance), 1)
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("img", {
            class: "iconimg",
            src: _imports_0,
            alt: ""
          }, null, -1))
        ]),
        _cache[10] || (_cache[10] = _createElementVNode("img", {
          class: "arrowimg",
          src: _imports_1,
          alt: ""
        }, null, -1)),
        _createElementVNode("div", _hoisted_13, [
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "title" }, "用户资产", -1)),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex-ai-c flex gap-10" }, [
                _createTextVNode("可发金额 "),
                _createElementVNode("img", {
                  src: _imports_2,
                  class: "questIcon",
                  alt: ""
                })
              ], -1)),
              _createElementVNode("div", _hoisted_16, "¥ " + _toDisplayString(_ctx.accountProductStatus.pay.canUseBalance), 1)
            ]),
            _createElementVNode("div", _hoisted_17, [
              _cache[7] || (_cache[7] = _createElementVNode("div", null, "待领金额", -1)),
              _createElementVNode("div", _hoisted_18, "¥ " + _toDisplayString(_ctx.accountProductStatus.pay.unclaimedSalary), 1)
            ]),
            _createElementVNode("div", _hoisted_19, [
              _cache[8] || (_cache[8] = _createElementVNode("div", null, "预支待领", -1)),
              _createElementVNode("div", _hoisted_20, "¥ " + _toDisplayString(_ctx.accountProductStatus.pay.advanceUnclaimedSalary), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _cache[11] || (_cache[11] = _createElementVNode("img", {
          class: "w-50-h-17",
          src: _imports_3,
          alt: ""
        }, null, -1)),
        _createElementVNode("div", {
          class: "btn btnbg1",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onWechatRecharge && _ctx.onWechatRecharge(...args)))
        }, "充值")
      ])
    ]),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "fs-18 bold" }, "支付宝账户余额", -1)),
            _createElementVNode("i", _hoisted_26, "¥" + _toDisplayString(_ctx.accountProductStatus.alipay.balance), 1)
          ]),
          _cache[13] || (_cache[13] = _createElementVNode("img", {
            class: "iconimg",
            src: _imports_4,
            alt: ""
          }, null, -1))
        ]),
        _cache[18] || (_cache[18] = _createElementVNode("img", {
          class: "arrowimg",
          src: _imports_1,
          alt: ""
        }, null, -1)),
        _createElementVNode("div", _hoisted_27, [
          _cache[17] || (_cache[17] = _createElementVNode("div", { class: "title" }, "用户资产", -1)),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("div", _hoisted_29, [
              _cache[14] || (_cache[14] = _createElementVNode("div", { class: "flex-ai-c flex gap-10" }, [
                _createTextVNode("可发金额 "),
                _createElementVNode("img", {
                  src: _imports_2,
                  class: "questIcon",
                  alt: ""
                })
              ], -1)),
              _createElementVNode("div", _hoisted_30, "¥ " + _toDisplayString(_ctx.accountProductStatus.alipay.canUseBalance), 1)
            ]),
            _createElementVNode("div", _hoisted_31, [
              _cache[15] || (_cache[15] = _createElementVNode("div", null, "待领金额", -1)),
              _createElementVNode("div", _hoisted_32, "¥ " + _toDisplayString(_ctx.accountProductStatus.alipay.unclaimedSalary), 1)
            ]),
            _createElementVNode("div", _hoisted_33, [
              _cache[16] || (_cache[16] = _createElementVNode("div", null, "预支待领", -1)),
              _createElementVNode("div", _hoisted_34, "¥ " + _toDisplayString(_ctx.accountProductStatus.alipay.advanceUnclaimedSalary), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_35, [
        _cache[19] || (_cache[19] = _createElementVNode("img", {
          class: "w-50-h-17",
          src: _imports_5,
          alt: ""
        }, null, -1)),
        _createElementVNode("div", {
          class: "btn btnbg2",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onShowDia && _ctx.onShowDia(...args)))
        }, "充值")
      ])
    ]),
    _createVNode(_component_alipayRechargeDialog, {
      show: _ctx.isShow,
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isShow) = $event)),
      num: _ctx.accountProductStatus.alipay.balance
    }, null, 8, ["show", "num"]),
    _createVNode(_component_wechatRechargeDialog, {
      show: _ctx.isShowwechat,
      "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isShowwechat) = $event))
    }, null, 8, ["show"])
  ]))
}