
import { defineComponent } from 'vue'
import store from '@/store'
import alipayRechargeDialog from '@/components/alipay-recharge-dialog/alipay-recharge-dialog.vue'
import wechatRechargeDialog from '@/components/wechat-recharge-dialog/wechat-recharge-dialog.vue'
import { AccountProductStatusDto, getAccountProductStatusApi } from '@/api/alipay-recharge'
import dayjs from 'dayjs'
export default defineComponent({
    name: 'AccountDetails',
    components: { alipayRechargeDialog, wechatRechargeDialog },

    data() {
        return {
            nowDate: '',
            accountProductStatus: {} as AccountProductStatusDto,
            isShow: false,
            isShowwechat: false,
            $store: store
        }
    },
    async created() {
        this.nowDate = dayjs().format('YYYY-MM-DD HH:mm:ss')
        let res = await getAccountProductStatusApi()
        this.accountProductStatus = res
    },
    methods: {
        /**
         * 支付宝充值弹窗
         */
        onShowDia() {
            this.isShow = true
        },
        /**
         * 微信充值
         */
        onWechatRecharge() {
            console.log(11)
            this.isShowwechat = true
        }
    }
})
